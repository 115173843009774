import { useEffect } from "react";
import { navigate } from "@reach/router";
import mapBrowserLanguageToPageLanguage from "../utils/mapBrowserLanguageToPageLanguage";

const AirportLanguageRedirect = ({ pageContext, location }) => {
  useEffect(() => {
    const { icao_code, languages } = pageContext;
    const preferredLanguages = window?.navigator
      ? [...(window.navigator.languages ?? window.navigator.language)]
      : [];

    // Get the first language available that matches the user's preference
    const language = mapBrowserLanguageToPageLanguage(
      preferredLanguages,
      languages
    );

    navigate(`/${icao_code.toLowerCase()}/${language}/${location.search}`);
  }, [pageContext, location.search]);

  return null;
};

export default AirportLanguageRedirect;
