/**
 * Finds the best suited language to show a page in, looking at the preferred languages
 * from a user's browser preferences.
 */
const mapBrowserLanguageToPageLanguage = (
  preferredLanguages = [],
  availableLanguages
) => {
  let familiarAvailableLanguage = null;

  preferredLanguages.some(preferredLanguage => {
    const matchFound = availableLanguages.find(
      availableLanguage =>
        availableLanguage.toLowerCase().substring(0, 2) ===
        preferredLanguage.toLowerCase().substring(0, 2)
    );
    if (matchFound) {
      familiarAvailableLanguage = matchFound;
      return true;
    } else {
      return false;
    }
  });

  return (
    familiarAvailableLanguage ??
    availableLanguages.find(l => l === "en") ??
    availableLanguages.find(l => l === "fi") ??
    availableLanguages[0]
  );
};

export default mapBrowserLanguageToPageLanguage;
